<template>
    <v-container fluid>
        <v-row>
            <v-col :align="'center'">
                <h1>{{ $t('connection.connectTitle') }}</h1>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-spacer v-if="$vuetify.breakpoint.xl"/>
            <v-col :align="'center'" xl="8">
                {{ $t('connection.connectDescription') }}
            </v-col>
            <v-spacer v-if="$vuetify.breakpoint.xl"/>
        </v-row>
        <v-row>
            <v-col>
                <v-alert type="info">{{ $t('connection.searchExchangeTip') }}</v-alert>
            </v-col>
        </v-row>
        <v-row v-if="!$vuetify.breakpoint.xs">
            <v-col>
                <v-text-field v-model="search" :label="$t('label.search')" solo prepend-inner-icon="mdi-magnify"></v-text-field>
            </v-col>
            <v-col>
                <v-btn class="my-2" color="accent" tile :href="redirect" target="_blank">
                    <v-icon>mdi-plus</v-icon>
                    <span>{{ $t('connection.requestNewIntegration') }}</span>
                </v-btn>
            </v-col>
        </v-row>
        <v-row v-if="$vuetify.breakpoint.xs">
            <v-col>
                <v-text-field v-model="search" :label="$t('label.search')" solo prepend-inner-icon="mdi-magnify"></v-text-field>
            </v-col>
        </v-row>
        <v-row v-if="$vuetify.breakpoint.xs" class="mb-3">
            <v-spacer></v-spacer>
            <v-col>
                <v-btn color="accent" tile :href="redirect" target="_blank">
                    <v-icon>mdi-plus</v-icon>
                    <span>{{ $t('connection.requestNewIntegration') }}</span>
                </v-btn>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
        <v-row no-gutters>
            <v-col cols="12">
                <v-btn-toggle v-model="tagFilter" :class="getButtonToggleClass" :tile="$vuetify.breakpoint.xs" color="accent">
                    <v-btn text>{{ $t('label.all') }}</v-btn>
                    <v-btn text>{{ $t('label.wallets') }}</v-btn>
                    <v-btn text>{{ $t('label.exchanges') }}</v-btn>
                    <v-btn text>{{ $t('label.blockchains') }}</v-btn>
                    <v-btn text>{{ $t('label.platforms') }}</v-btn>
                    <v-btn text>{{ $t('label.csv') }}</v-btn>
                </v-btn-toggle>
            </v-col>
        </v-row>
        <v-row>
            <v-col v-for="(item, index) in filteredItems" :key="index" cols="12" sm="4" md="3" lg="2">
                <v-card @click="navigateToItem(item.id)">
                    <v-img :src="item.imageUrl" height="150" contain >
                        <div class="card-title-overlay">{{ item.title }}</div>
                    </v-img>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'add-connection-page',
    components: {},
    data() {
        return {
            search: "",
            tagFilter: 0,
            tagFilters: ["", "Wallet", "Exchange", "Blockchain", "Platform", "CSV"],
            redirect: "https://forms.gle/3hjaAMQAD6Dpk62N7"
        };
    },
    computed: {
        getItems() {
            let items = []
            items.push({id: "BITCOIN", title: this.$options.filters.BlockchainTypeFormatter("BITCOIN"), imageUrl: require(`@/assets/connection/BITCOIN.png`), tag: "Blockchain"})
            items.push({id: "ETHEREUM", title: this.$options.filters.BlockchainTypeFormatter("ETHEREUM"), imageUrl: require(`@/assets/connection/ETHEREUM.png`), tag: "Blockchain"})
            items.push({id: "BINANCE", title: this.$options.filters.BlockchainTypeFormatter("BINANCE"), imageUrl: require(`@/assets/connection/BINANCE.png`), tag: "Exchange"})
            items.push({id: "CRYPTOCOM", title: this.$options.filters.BlockchainTypeFormatter("CRYPTOCOM"), imageUrl: require(`@/assets/connection/CRYPTOCOM.png`), tag: "Exchange"})
            items.push({id: "CRYPTOCOMEXCHANGE", title: this.$options.filters.BlockchainTypeFormatter("CRYPTOCOMEXCHANGE"), imageUrl: require(`@/assets/connection/CRYPTOCOMEXCHANGE.png`), tag: "Exchange"})
            items.push({id: "NEXO", title: this.$options.filters.BlockchainTypeFormatter("NEXO"), imageUrl: require(`@/assets/connection/NEXO.png`), tag: "Exchange"})
            items.push({id: "COINBASE", title: this.$options.filters.BlockchainTypeFormatter("COINBASE"), imageUrl: require(`@/assets/connection/COINBASE.png`), tag: "Exchange"})
            items.push({id: "YOUNGPLATFORM", title: this.$options.filters.BlockchainTypeFormatter("YOUNGPLATFORM"), imageUrl: require(`@/assets/connection/YOUNGPLATFORM.png`), tag: "Exchange"})
            items.push({id: "CRYPTOSMART", title: this.$options.filters.BlockchainTypeFormatter("CRYPTOSMART"), imageUrl: require(`@/assets/connection/CRYPTOSMART.png`), tag: "Exchange"})
            for (const exchange of this.$SUPPORTED_EXCHANGES.filter(exchange => exchange !== "GENERIC" && exchange !== "STAKETAX" && !this.$SUPPORTED_PLATFORMS.includes(exchange))){
                if (items.find(item => item.id === exchange)) continue;
                items.push({
                    id: exchange,
                    title: this.$options.filters.BlockchainTypeFormatter(exchange),
                    imageUrl: require(`@/assets/connection/${exchange}.png`),
                    tag: "Exchange"
                })
            }
            for (const blockchain of this.$SUPPORTED_BLOCKCHAINS) {
                if (items.find(item => item.id === blockchain)) continue;
                items.push({
                    id: blockchain,
                    title: this.$options.filters.BlockchainTypeFormatter(blockchain),
                    imageUrl: require(`@/assets/connection/${blockchain}.png`),
                    tag: "Blockchain"
                })
            }

            for (const wallet of this.$SUPPORTED_WALLETS){
                if (items.find(item => item.id === wallet)) continue;
                items.push({
                    id: wallet,
                    title: this.$options.filters.WalletTypeFormatter(wallet),
                    imageUrl: require(`@/assets/connection/${wallet}.png`),
                    tag: "Wallet"
                })
            }
            for (const platform of this.$SUPPORTED_PLATFORMS){
                if (items.find(item => item.id === platform)) continue;
                items.push({
                    id: platform,
                    title: this.$options.filters.PlatformTypeFormatter(platform),
                    imageUrl: require(`@/assets/connection/${platform}.png`),
                    tag: "Platform"
                })
            }
            items.push({
                id: "GENERIC",
                title: this.$t('label.csv'),
                imageUrl: require(`@/assets/connection/GENERIC.png`),
                tag: "CSV"
            })
            items.push({
                id: "STAKETAX",
                title: "StakeTax",
                imageUrl: require(`@/assets/connection/STAKETAX.png`),
                tag: "CSV"
            })
            return items
        },
        filteredItems() {
            return this.getItems.filter(
                (item) =>
                    item.title.toLowerCase().includes(this.search.toLowerCase()) &&
                    (this.tagFilters[this.tagFilter] === "" || item.tag === this.tagFilters[this.tagFilter])
            );
        },
        getButtonToggleClass() {
            return this.$vuetify.breakpoint.xs ? "d-flex flex-column justify-center" : "";
        }
    },
    methods: {
        navigateToItem(id) {
            if (this.$SUPPORTED_BLOCKCHAINS.includes(id) || this.$SUPPORTED_WALLETS.includes(id)){
                this.$router.push(`/add-address/${id.toLowerCase()}`);
            } else if (this.$SUPPORTED_EXCHANGE_CSV.includes(id) || this.$SUPPORTED_PLATFORMS.includes(id)){
                this.$router.push(`/add-exchange-csv/${id.toLowerCase()}`);
            } else {
                this.$router.push(`/add-exchange/${id.toLowerCase()}`);
            }
        },

    },
}
</script>

<style>
.card-title-overlay {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 10px;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

.v-card:hover .card-title-overlay {
    opacity: 1;
}
</style>